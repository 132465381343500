// React
import React from "react";

// Components
import Seo from "../components/Seo";
import {PublicNavbar} from "../components/Navbars";
import Footer from "../components/Footer";
import TermsOfService from "../components/TermsOfService";

// Material ui
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

export default function TermsPage() {
  return (
    <Box>
      <Seo
        title="Privacy policy"
        description="My page description"
        pathname="/privacy-policy"
        article={null}
      />
      <PublicNavbar/>
      <Container>
        <TermsOfService/>
      </Container>
      <Footer/>
    </Box>
  );
}

// TermsOfService.propTypes = {
//   // theme: PropTypes.object,
//   // classes: PropTypes.object,
// };
